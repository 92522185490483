import React, { useState, useEffect } from 'react';
import choreService from '../services/choreService';
import ChoreList from '../components/ChoreList';
import Rewards from '../components/Rewards';
import CHNavbar from '../components/Navbar';
import ChoreForm from '../components/ChoreForm';
import ResetButton from '../components/ResetButton';
import { fetchChores, fetchUsers, mergeUserNamesIntoChores } from '../services/choreService';
import { Container, Modal, Form, Button, Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const AdminDashboard = () => {
    const [chores, setChores] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedChore, setSelectedChore] = useState(null);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [rejectionExplanation, setRejectionExplanation] = useState('');
    // eslint-disable-next-line
    const [error, setError] = useState(null);
    const reviewChores = chores.filter(chore => chore.status === 'in review');
    const [imageLoading, setImageLoading] = useState({});    
    const [showEditImage, setShowEditImage] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [chores, users] = await Promise.all([fetchChores(), fetchUsers()]);
                setUsers(users);
                setChores(mergeUserNamesIntoChores(chores, users));
            } catch (err) {
                setError('Failed to fetch data');
            }
        };
        fetchData();
        const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');

        ws.onopen = () => {
            console.log('Admin WebSocket connection established');
        };
    
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
    
            if (message.type === 'CHORE_DELETED' || message.type === 'NEW_CHORE_ADDED' || message.type === 'CHORE_SELECTED' || message.type === 'CHORE_REVIEW' || message.type === 'CHORE_COMPLETED' || message.type === 'CHORE_UPDATED') {
                fetchData(); // Re-fetch the chores when a chore is added or deleted
            }
        };
    
        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    
        ws.onclose = () => {
            console.log('WebSocket connection closed');
        };
    
        return () => {
            ws.close();
        };
    }, []);

    const handleReviewChore = (chore) => {
        setSelectedChore(chore);
        setShowReviewModal(true);
    };

    const handleRejectChore = () => {
        if (!rejectionExplanation.trim()) {
            setError('Rejection explanation is required.');
            return;
        }
        handleReviewAction('rejected');
    };

    const handleReviewAction = async (status) => {
        try {
            await choreService.reviewChore(selectedChore._id, status, rejectionExplanation);
            setChores(chores.map(chore => (chore._id === selectedChore._id ? { ...chore, status, rejectionExplanation } : chore)));
            setShowReviewModal(false);
            setShowRejectionModal(false);
            setSelectedChore(null);
            setRejectionExplanation('');
            fetchChores();
            // Notify the child dashboard about the completed chore
            const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');
            ws.onopen = () => {
                ws.send(JSON.stringify({ type: 'CHORE_COMPLETED' }));
                ws.close();
            };
        } catch (err) {
            console.error('Error reviewing chore:', err);
            setError('Failed to review chore');
        }
    };

    const handleChoreSubmitted = async (choreData) => {
        try {
            //console.log('Chore data being submitted:', choreData);
            if (selectedChore) {
                await choreService.updateChore(selectedChore._id, choreData); // Use choreService for update
            } else {
                const newChore = await choreService.createChore(choreData); // Use choreService for create
                setChores([...chores, newChore.data].sort((a, b) => b.points - a.points));
            }
            fetchChores();
            setShowEditModal(false);
        } catch (error) {
            console.error('Error submitting chore:', error);
            setError('Failed to submit chore');
        }
    };
    
    const handleChoreDeleted = async (id) => {
        try {
            setChores(chores.filter(chore => chore._id !== id));
            await choreService.deleteChore(id); // Use choreService for delete
            fetchChores();
        } catch (error) {
            console.error('Error deleting chore:', error);
        }
    };

    const handleAddChore = () => {
        setSelectedChore(null);
        setShowAddModal(true);
        fetchChores();
    };

    const handleEditChore = (chore) => {
        setSelectedChore(chore);
        setShowEditModal(true);
        fetchChores();
    };

    const handleToggleEditImageClick = () => {
        setShowEditImage(!showEditImage);
    };

    return (
        <Container className="mb-5 a-dash">

            <CHNavbar title="Admin Dashboard" />
            
            <ChoreList
                chores={chores}
                onEditChore={handleEditChore}
                onAddChore={handleAddChore}
                handleReviewChore={handleReviewChore}
                reviewChores={reviewChores}
                isAdmin={true}
            />
            
            <Rewards isAdmin={true} />

            <Modal show={showReviewModal} onHide={() => setShowReviewModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Review Chore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedChore && (
                        <>
                            <p>{selectedChore.description}</p>
                            {selectedChore.imagePath && (
                                <div className="chore-image-container">
                                    <div
                                        className="chore-image-spinner"
                                        style={{ display: imageLoading ? 'flex' : 'none' }}
                                    >
                                        <Spinner animation="grow" />
                                    </div>
                                    {selectedChore.imagePath.endsWith('.mp4') || selectedChore.imagePath.endsWith('.mov') || selectedChore.imagePath.endsWith('.MOV') ? (
                                        <video 
                                            alt="Chore Evidence" 
                                            className="chore-image"
                                            controls
                                            autoPlay
                                            onLoadedData={() => setImageLoading(false)}
                                            onError={() => setImageLoading(false)}
                                        >
                                            <source 
                                                src={selectedChore.imagePath} 
                                                className="chore-image"
                                                type={selectedChore.imagePath.endsWith('.mp4') ? 'video/mp4' : 'video/mp4'}
                                                style={{ display: imageLoading ? 'none' : 'block' }}
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img 
                                            src={selectedChore.imagePath} 
                                            alt="Chore Evidence" 
                                            className="chore-image"
                                            style={{ display: imageLoading ? 'none' : 'block' }}
                                            onLoad={() => setImageLoading(false)}
                                            onError={() => setImageLoading(false)}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowReviewModal(false)}>
                        Close
                    </Button>
                    <Button variant="success" onClick={() => handleReviewAction('completed')}>
                        Approve
                    </Button>
                    <Button variant="danger" onClick={() => { setShowReviewModal(false); setShowRejectionModal(true); }}>
                        Reject
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRejectionModal} onHide={() => setShowRejectionModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Rejection Explanation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="rejectionExplanation">
                        <Form.Label>Rejection Explanation</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={rejectionExplanation}
                            onChange={(e) => setRejectionExplanation(e.target.value)}
                            required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowRejectionModal(false); setShowReviewModal(true); }}>
                        Back
                    </Button>
                    <Button variant="danger" onClick={handleRejectChore}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Chore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showEditImage && selectedChore && selectedChore.imagePath && (
                        <div className="chore-image-container mb-3">
                            <div
                                className="chore-image-spinner"
                                style={{ display: imageLoading ? 'flex' : 'none' }}
                            >
                                <Spinner animation="grow" />
                            </div>
                            {selectedChore.imagePath && (
                                selectedChore.imagePath.endsWith('.mp4') || 
                                selectedChore.imagePath.endsWith('.mov') || 
                                selectedChore.imagePath.endsWith('.MOV') ? (
                                    <video
                                        src={selectedChore.imagePath}
                                        alt="Chore Evidence"
                                        className="chore-image"
                                        controls
                                        autoPlay
                                        onLoadedData={() => setImageLoading(false)} // Use onLoadedData for videos instead of onLoad
                                        onError={() => setImageLoading(false)}
                                    >
                                        <source
                                            src={selectedChore.imagePath}
                                            type={selectedChore.imagePath.endsWith('.mp4') ? 'video/mp4' : 'video/quicktime'}
                                            className="chore-image"
                                            style={{ display: imageLoading ? 'none' : 'block' }}
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <img
                                        src={selectedChore.imagePath}
                                        alt="Chore Evidence"
                                        className="chore-image"
                                        style={{ display: imageLoading ? 'none' : 'block' }}
                                        onLoad={() => setImageLoading(false)}
                                        onError={() => setImageLoading(false)}
                                    />
                                )
                            )}
                        </div>
                    )} 
                    {selectedChore && selectedChore.imagePath && (                  
                        <Button className='toggle-edit-image' onClick={handleToggleEditImageClick}>
                            {showEditImage ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                    )}
                    <ChoreForm
                        onChoreSubmitted={handleChoreSubmitted}
                        initialChore={selectedChore}
                        users={users}
                        choreStatusOptions={['new', 'pending', 'in review', 'completed', 'rejected']}
                        closeModal={() => setShowEditModal(false)}
                        onChoreDeleted={handleChoreDeleted}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Chore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChoreForm
                        onChoreSubmitted={handleChoreSubmitted}
                        users={users}
                        choreStatusOptions={['new', 'pending', 'in review', 'completed', 'rejected']}
                        closeModal={() => setShowAddModal(false)}
                    />
                </Modal.Body>
            </Modal>
            <ResetButton />
        </Container>
    );
};

export default AdminDashboard;