// frontend/src/components/ResetButton.js
import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

const ResetButton = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleReset = async () => {
    try {
      await axios.delete('/api/reset/reset');
      alert('All chores deleted and user points reset to 0');
    } catch (error) {
      alert('Error resetting chores and user points');
    }
    setShowConfirmation(false);
  };

  return (
    <div>
      <Button variant="danger" onClick={() => setShowConfirmation(true)}>Reset Chores and Points</Button>
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to reset all chores and user points? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmation(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleReset}>Yes, Reset</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResetButton;